import { render, staticRenderFns } from "./OutboundQueueTable.vue?vue&type=template&id=2fc14d2f"
import script from "./OutboundQueueTable.vue?vue&type=script&lang=ts"
export * from "./OutboundQueueTable.vue?vue&type=script&lang=ts"
import style0 from "./OutboundQueueTable.vue?vue&type=style&index=0&id=2fc14d2f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QBtn,QTd,QMenu,QList,QItem,QChip});
