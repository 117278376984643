
import Vue from "vue";
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';;
import { Warn, Info } from "@/services/notif-utils";
export default Vue.extend({
  name: "OutboundQueueModal",
  props: ["show", "rowData"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
    copy(text: string) {
      copyToClipboard(text)
        .then(() => {
          Info(`${text} Copied to clipboard successfully :)`);
          this.$emit("close");
        })
        .catch(() => {
          Warn("Failed to copy :(");
        });
    },
  },
  computed: {
    showModal(): boolean {
      return this.show;
    },
    data(): any {
      return this.rowData;
    },
  },
});
