
import Api from "@/api/index";
import Vue from "vue";
import OutboundQueueTable from "@/components/OutboundQueueTable.vue";
import { mapActions, mapGetters } from "vuex";
import { ActionTypes } from "@/store/messages/action-types";
export default Vue.extend({
  name: "OutboundQueue",
  components: {
    OutboundQueueTable
  },
  data() {
    return {
      val: [],
      loading: false,
      initialPagination: {
        sortBy: "desc",
        descending: false,
        page: 1,
        rowsNumber: 50
      }
    };
  },
  computed: {
    outboundQueue() {
      return this.$store.getters["Message/getOutboundQueue"];
    },
    outboundLoaded() {
      return this.$store.getters["Message/getOutboundLoaded"];
    },
    loadingData() {
      if (this.outboundQueue.length > 0) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    async loadOutbound() {
      this.loading = true;
      await this.$store.dispatch("Message/" + ActionTypes.LOAD_OUTBOUND_QUEUE);
      this.loading = false;
    },
    setLoader() {
      this.loading = true;
    },
    async refreshTable() {
      this.loading = true;
      await this.loadOutbound();
      this.loading = false;
    }
  }
  // created() {
  //   if (!this.outboundLoaded) {
  //     this.loadOutbound();
  //   }
  // },
});
