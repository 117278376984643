
import { ActionTypes } from "@/store/messages/action-types";
import Vue from "vue";
import api from "@/api/staff";
import { mapActions } from "vuex";
import ModalOutboundQueue from "./ModalOutboundQueue.vue";

export default Vue.extend({
  name: "OutboundQueueTable",
  components: {
    ModalOutboundQueue,
  },
  // props: ["messages"],
  data() {
    return {
      columns: [
        {
          name: "type_",
          label: "Type ID",
          field: (row: any) => row.type_.name,
          align: "left",
        },
        {
          name: "message_id",
          label: "Message ID",
          field: "message_id",
          align: "center",
        },
        {
          name: "message",
          label: "Message Snippet",
          field: "message",
          align: "left",
        },
        {
          name: "order_id",
          label: "Order ID",
          field: "order_id",
          align: "center",
        },
        // {
        //   name: "processed",
        //   label: "Processed",
        //   field: "processed",
        //   align: "center",
        // },
        {
          name: "success",
          label: "Satus",
          field: "success",
          align: "center",
        },
        {
          name: "actions",
          label: "Expand Details",
          align: "center",
        },
      ],
      showDetails: false,
      loading: false,
      initialPagination: {
        sortBy: "desc",
        descending: false,
        page: 1,
        rowsNumber: 50,
        rowsPerPage: 50,
      },
      selectedRow: {},
    };
  },
  computed: {
    isLoading(): boolean {
      return this.loading;
    },
    outboundQueue() {
      return this.$store.getters["Message/getOutboundQueue"];
    },
  },
  methods: {
    ...mapActions({
      loadOutboundQueue: "Message/" + ActionTypes.LOAD_OUTBOUND_QUEUE,
    }),
    openModal(row: any) {
      this.showDetails = true;
      this.selectedRow = row;
      return;
    },
    closeModal() {
      this.showDetails = false;
      this.selectedRow = {};
    },
    refresh() {
      this.$emit("refresh");
    },
    getChipColor(row: any) {
      const processed = Boolean(row.processed);
      const success = Boolean(row.success);
      if (processed) {
        if (success) {
          return "positive";
        }
        return "negative";
      }
      return "Processing";
    },
    getChipText(row: any) {
      const processed = Boolean(row.processed);
      const success = Boolean(row.success);
      if (processed) {
        if (success) {
          return "Success";
        }
        return "Failed";
      }
      return "Processing";
    },
    async paginator(props: any) {
      this.initialPagination.page = props.pagination.page;
      await this.loadOutbound();
    },
    loadOutbound() {
      this.loading = true;
      const pagination = this.initialPagination;
      this.loadOutboundQueue(pagination)
        .then((res) => {
          this.initialPagination.rowsNumber = res.total_count;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
      this.loading = false;
    },
  },
  created() {
    this.loadOutbound();
  },
});
